import "./AuthenticationPage.scss";
import { Form } from "react-bootstrap";
import { useState, useContext, useCallback } from "react";
import { SendRequest } from "../../services/RequestHandler";
import Swal from "sweetalert2";
import Context from "../../context/context";
import Tooltip from "../../components/Tooltip/Tooltip";
import { Encrypt, Decrypt } from "../../utils/SessionCrypto/Crypto";
import images from "../../assets/images";
import Button from "../../components/Button/Button";
import ConfigManager from "../../settings/ConfigManager";

export default function FormClaveUnicaFAO() {
    const { state, actions } = useContext(Context);
    const [number, setNumber] = useState("");

    const onChange = useCallback((event) => {
        const value = event.target.value;
        const re1try = /^[aA]$/;
        const re2try = /^[aA][0-9]{1,9}$/;
        const reOnlyNumbers = /^[0-9]{1,9}$/;

        if (re1try.test(value) || value === "" || re2try.test(value) || reOnlyNumbers.test(value)) {
            setNumber(value);
        }
    }, []);

    const AutenticateCI = async () => {
        try {
            await setLoading(true);

            const data = Decrypt("data");
            data.autenticacion = "CU";
            Encrypt("data", data);

            const result = await SendRequest({
                url: `${ConfigManager.API_BACK_URL}/api/ecert/authenticate/CI/${data.run}/${data.device}`,
                method: "POST",
                body: null
            });

            if (result.code === 0) {
                await ValidateCI(data, result.data.ObjetoGenerico);
            } else {
                Swal.fire({
                    icon: "error",
                    text: result.Descripcion,
                    confirmButtonText: "Ok",
                    allowOutsideClick: false,
                });
            }
        } finally {
            await setLoading(false);
        }
    };

    const ValidateCI = async (data, token) => {
        try {
            const result = await SendRequest({
                url: `${ConfigManager.API_BACK_URL}/api/ecert/validate/ci`,
                method: "POST",
                body: JSON.stringify({
                    Datos: { Run: data.run, NumeroSerie: number },
                    Token: token,
                    Device: data.device
                })
            });
            
            if (result.code === 0 && result.data.Exito) {
                await AuthenticateCU(data);
            } else {
                handleValidationErrors(result);
            }
        } finally {

        }
    };

    const handleValidationErrors = (result) => {
        const dataCliente = result.data.ObjetoGenerico.DatosCliente;
        const errors = {
            valida: dataCliente.EstadoRespuesta !== "Consulta exitosa",
            bloqueada: dataCliente.IndicadorBloqueo !== "NOBLOQUEADO",
            vencida: dataCliente.Vigencia !== "S"
        };

        if (errors.valida) {
            Swal.fire({
                title: "Número de documento incorrecto",
                icon: "error",
                text: "El número de documento que ingresaste no corresponde al de tu cédula de identidad. Inténtalo nuevamente.",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
            });
        } else if (errors.bloqueada || errors.vencida) {
            Swal.fire({
                icon: "error",
                text: errors.bloqueada ? "Tu cédula está bloqueada." : "Tu cédula está vencida.",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
            }).then(() => {
                Swal.fire({
                    icon: "info",
                    html: "Puedes completar tu traspaso mediante tu <b>clave de seguridad</b>.",
                    confirmButtonText: "Ok",
                    allowOutsideClick: false,
                });
            });
        }
    };

    const AuthenticateCU = async (data) => {
        try {
            const result = await SendRequest({
                url: `${ConfigManager.API_BACK_URL}/api/ecert/authenticate/${data.run}/${data.device}`,
                method: "POST",
                body: null
            });

            if (result.code === 0 && result.data.Exito) {
                data.token = result.data.ObjetoGenerico.TokenUser.TokenUser;
                Encrypt("data", data);
                await SolicitudCU(data);
            } else {
                Swal.fire({
                    icon: "error",
                    text: result.Descripcion,
                    confirmButtonText: "Ok",
                    allowOutsideClick: false,
                });
            }
        } finally {

        }
    };

    const SolicitudCU = async (data) => {
        try {
            const result = await SendRequest({
                url: `${ConfigManager.API_BACK_URL}/api/ecert/validate/cu`,
                method: "POST",
                body: JSON.stringify({
                    Run: data.run,
                    UrlCallback: `${window.location.origin}/validate`,
                    Nombres: data.name,
                    ApellidoPaterno: data.fatherName,
                    ApellidoMaterno: data.motherName,
                    Correo: data.email,
                    Token: data.token,
                    NumeroSerie: number,
                    Device: data.device
                }),
            });

            if (result.code === 0 && result.data.Exito) {
                await setLoading(false);
                const resultado = result.data.ObjetoGenerico.Resultado;
                data.identificador = resultado.Identificador;
                data.tokentvi = resultado.IdentificadorPrevired;
                Encrypt("data", data);

                let timerInterval;
                Swal.fire({
                    icon: "success",
                    title: "ClaveÚnica",
                    html: '<ul>Se han enviado los datos de manera exitosa.</ul><ul>Se redireccionará automáticamente al sitio. <b class="d-none"></b></ul>',
                    timer: 5000,
                    timerProgressBar: true,
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                        const b = Swal.getHtmlContainer().querySelector("b");
                        timerInterval = setInterval(() => {
                            b.textContent = Swal.getTimerLeft();
                        }, 100);
                    },
                    willClose: () => {
                        clearInterval(timerInterval);
                    },
                }).then((timerResult) => {
                    if (timerResult.dismiss === Swal.DismissReason.timer) {
                        window.location.replace(resultado.UrlClaveUnica);
                    }
                });
            } else {
                handleValidationErrors(result);
            }
        } finally {
            await setLoading(false);
        }
    };

    const setLoading = async (isLoading) => {
        actions({ type: "setState", payload: { ...state, isLoading } });
    };

    return (
        <div className="autentication mt-3 mb-3">
            <div className="col-sm-10 col-md-10 col-lg-6 offset-sm-1 offset-md-1 offset-lg-3">
                <div className="account">
                    <div className="account-type">
                        <div className="row text-sm-center text-md-center text-lg-start">
                            <div className="col-sm-12 col-md-12 col-lg-9">
                                <div>
                                    <h1>¡Ya te estás cambiando</h1>
                                    <h1>a AFP Modelo!</h1>
                                </div>
                                <div>
                                    <p>Sigue el paso a paso para ser parte de Modelo.</p>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-3">
                                <img src={images.girl} height={160} alt="girl" className="mx-auto d-block" />
                            </div>
                        </div>
                        <div className="mt-3">
                            <div className="row">
                                <div className="col-lg-11 d-none d-lg-block">
                                    <span className="resaltar">*</span> Ingresa el Número de documento de tu Cédula de Identidad
                                </div>
                                <div className="col-lg-1 d-none d-lg-block">
                                    <Tooltip text="Número de Documento" image={images.cedula} />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <Form.Control
                                        value={number}
                                        type="text"
                                        placeholder="Ingresa el Número de documento de tu Cédula de Identidad"
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="offset-4 col-4">
                                    <Button
                                        type="button"
                                        variant="primary"
                                        onClick={AutenticateCI}
                                        disabled={number === ""}
                                        loading={state.loading}
                                        block
                                    >
                                        Siguiente
                                    </Button>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="offset-4 col-4">
                                    <Button
                                        type="button"
                                        variant="link"
                                        block
                                        onClick={() => window.location.replace("/autenticacion")}
                                    >
                                        Volver
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
