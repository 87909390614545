import Swal from "sweetalert2";
import { useState, useEffect, useContext } from "react";
import { Redirect as BrowserRouter } from "react-router-dom";
import { SendRequest } from "../../services/RequestHandler";
import { Encrypt, Decrypt } from "../../utils/SessionCrypto/Crypto";
import Context from "../../context/context";
import ConfigManager from "../../settings/ConfigManager";
import response from "../../utils/markup/previred.json";
import registerStep from "../../services/registerStep";
import { Spinner } from "react-bootstrap";

export default function FormTVI() {
	const { state, authenticated, actions } = useContext(Context);
	const [redirect, setRedirect] = useState(false);

	useEffect(() => {
		const FetchTicket = async (data) => {
			try {
				await setLoading(true);

				var result = await SendRequest({
					url: `${ConfigManager.API_BACK_URL}/api/Previred/Obtener/Ticket/${data.device}`,
					method: "POST",
					body: JSON.stringify(
						{
							codigo_institucion: "1034",
							requiere_segunda_verificacion: false,
							afiliado: {
								nombre: data.name,
								rut_numero: data.run.substring(0, data.run.length - 1),
								dv: data.run.substring(data.run.length - 1),
								verificacion: {
									identificacion_proveedor: "VI08",
									url_verificacion: data.ObjetoGenerico.DatosPreviredTraspaso.UrlValidacionPrevired,
									tipo_verificacion: "1005",
									codigo_verificacion: data.tokentvi,
									url_usuario: data.ObjetoGenerico.DatosPreviredTraspaso.UsuarioValidacionPrevired,
									url_clave: data.ObjetoGenerico.DatosPreviredTraspaso.ClaveValidacionPrevired
								}
							}
						}
					)
				});

				if (result.code === 0) {
					var respuesta = JSON.parse(result.data);

					if (respuesta.respuesta.codigo === 200) {
						await FetchToken(respuesta.respuesta.ticket_tvi, data.run, data.autenticacion, data.device);
					}
				} else {
					ErrorMessage(result.message)
				}
			} finally {
				await setLoading(false);
			}
		};

		FetchTicket(Decrypt("data"));
	}, []);

	const FetchToken = async (ticket, run, autenticacion, device) => {
		try {
			await setLoading(true);

			var result = await SendRequest({
				url: `${ConfigManager.API_BACK_URL}/api/Previred/Obtener/Token/${device}`,
				method: "POST",
				body: JSON.stringify({
					rut: run,
					codigo_institucion: "1034",
					ticket_tvi: ticket,
				})
			});

			if (result.code === 0) {
				var da = JSON.parse(result.data);

				if (da.respuesta.codigo === 200) {
					await fetchDataPrevired(da.respuesta.token, run, autenticacion, device);
				} else {
					var message = da.respuesta.mensaje;

					if (da.respuesta.errores !== null) {
						da.respuesta.errores.forEach((element) => {
							message = `<b>${element.error.codigo_error}</b> ${element.error.mensaje}<br>`;
						});
					}
					ErrorMessage(message);
				}
			} else {
				ErrorMessage(result.message)
			}
		} finally {
			await setLoading(false);
		}
	};

	const addOption = async (arr) => {
		const steps = [];

		for (var i = 1; i <= arr.length; i++) {
			steps.push(i.toString());
		}

		const swalQueueStep = Swal.mixin({
			confirmButtonText: "Siguiente",
			cancelButtonText: "Anterior",
			progressSteps: steps,
			reverseButtons: true,
		});

		const values = [];
		let currentStep;

		for (currentStep = 0; currentStep < steps.length;) {
			var text = `La ${arr[currentStep].nombreTipo} perteneciente a ${arr[currentStep].origen} presenta el siguiente problema: ${arr[currentStep].causalRechazo}`;

			if (arr[currentStep].causalRechazoCodigo === 15) {
				text = `La ${arr[currentStep].nombreTipo} que intentas traspasar desde AFP ${arr[currentStep].origen} se encuentra bloqueada, dado que presentas una medida cautelar vigente por concepto de la Ley de Pensión de Alimentos (ley 21.484)`;
			}

			if (arr[currentStep].causalRechazoCodigo === 16) {
				text = `La ${arr[currentStep].nombreTipo} que intentas traspasar desde AFP ${arr[currentStep].origen} se encuentra bloqueada, dado que presentas una medida cautelar vigente por concepto de la Ley de Pensión de Alimentos (Retiros 10%)`;
			}
			
			const result = await swalQueueStep.fire({
				title: "No es posible concretar tu solicitud",
				showCancelButton: currentStep > 0,
				html: text,
				currentProgressStep: currentStep,
			});

			if (result.value) {
				values[currentStep] = result.value;
				currentStep++;
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				currentStep--;
			} else {
				break;
			}
		}
	};

	const fetchDataPrevired = async (token, run, autenticacion, device) => {
		try {
			registerStep(1, true);

			var result = {};
			if (ConfigManager.MARKUP) {
				result = response.datos[ConfigManager.MARKUP_INDEX];
			} else {
				result = await SendRequest({
					url: `${ConfigManager.API_BACK_URL}/api/Previred`,
					method: "POST",
					body: JSON.stringify({
						token: token,
						codigo: "1034",
						rut: run.substring(0, run.length - 1),
						rutDv: run.substring(run.length - 1),
						nombres: "",
						apellidoPaterno: "",
						apellidoMaterno: "",
						cuentas: [
							{ codigo: "CCICO" },
							{ codigo: "CCIAV" },
							{ codigo: "CAI" },
							{ codigo: "CAV" },
							{ codigo: "CCICV" },
							{ codigo: "CCIDC" },
							{ codigo: "APVC" },
						],
						otros: "",
						autenticacion: autenticacion,
						Device: device
					})
				});
			}

			if (result.code === 0) {
				if (result.data.cuentas.length === 0) {
					//ErrorMessage("Trabajador no registra cuentas activas");
					if (result.data.cuentasRechazo.length !== 0) {
						addOption(result.data.cuentasRechazo);
					}
				} else {
					var data = Decrypt("data");
					data.name = result.data.afiliado.nombres;
					data.fatherName = result.data.afiliado.apellidoPaterno;
					data.motherName = result.data.afiliado.apellidoMaterno;
					data.email = result.data.afiliado.email;
					data.token = token;
					data.isAuthenticated = true;

					Encrypt("data", data);
					result.data.token = token;

					result.data.cuentas.forEach((item) => {
						item.fondos.forEach((fondo, i) => {
							fondo.origen = fondo.nombre;
						});
					});

					actions({ type: "setAuthenticated", payload: { ...authenticated, isAuthenticated: true } });

					Encrypt("dataPrevired", result.data);
					setRedirect(true);
				}
			} else {
				ErrorMessage(result.message);
			}
		}
		finally {
			await setLoading(false);
		}
	};

	const ErrorMessage = (text) => {
		Swal.fire({
			icon: "error",
			html: text,
			showDenyButton: false,
			showCancelButton: false,
			confirmButtonText: "Ok",
			denyButtonText: `Don't save`
		}).then((result) => {
			if (result.isConfirmed) {
				window.location.replace("/");
			}
		});
	}

	const setLoading = async (isLoading) => {
		actions({ type: "setState", payload: { ...state, isLoading } });
	};

	return (
		<>
			{redirect && (<BrowserRouter to={{ pathname: "/cuentasyfondo" }} />)}
			<div className="d-flex justify-content-center mt-5" style={{ height: "250px" }}>
				<Spinner animation="grow" role="status" variant="info" className="mt-3">
					<span className="visually-hidden">Loading...</span>
				</Spinner>
			</div>
		</>
	);
}
